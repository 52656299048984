<content-layout-wrapper
    class="flex-1"
    [config]="config"
    [autoLeftContentFullscreen]="autoLeftContentFullscreen"
    *transloco="let t; read: 'bpOrganisation'">
    <div leftContentDrawerHeader class="flex flex-row justify-end">
        <button
            mat-icon-button
            *appHasAnyRole="['ADM', 'CON']"
            [ngClass]="currentComponent === 'org2' ? 'hidden' : ''"
            [matTooltip]="_translocoContent()['deleteOrganisationTooltip']"
            (click)="deleteItem()"
            [disabled]="!selectedItem()">
            <mat-icon>delete</mat-icon>
        </button>

        <button
            mat-icon-button
            *appHasAnyRole="['ADM', 'CON']"
            [ngClass]="currentComponent === 'org2' ? 'hidden' : ''"
            [matTooltip]="_translocoContent()['editOrganisationTooltip']"
            [color]="isEditable() ? 'primary' : ''"
            (click)="changeEditStatus()"
            [disabled]="!selectedItem() || (selectedItem() && !inDevelopmentStatus)">
            <mat-icon>edit</mat-icon>
        </button>
        <action-bar
            *appHasAnyRole="['ADM', 'CON']"
            [ngClass]="currentComponent === 'org2' ? 'hidden' : ''"
            [actionDescriptors]="actions"
            [executeActionSignal]="executeActionSignal"
            [dropdownMode]="true"></action-bar>
    </div>
    <div leftContentDrawer>
        <org-sys-area-tree
            [type]="currentComponent"
            [orgSysAreaService]="service"
            [filterToActive]="false"></org-sys-area-tree>
    </div>

    <flowchart-header leftContentHeader [config]="flowchartHeaderConfig()"></flowchart-header>
    <div leftContent>
        <organisation-container
            (onDoubleClicked)="onDoubleClicked($event)"
            [config]="orgChartComponentConfig"
            [type]="chartType"></organisation-container>
    </div>

    <div headerExtraContent *appHasAnyRole="['ADM', 'CON']"
        ><button
            mat-icon-button
            *ngIf="canBeSaved"
            [matTooltip]="_translocoContent()['save']"
            saveButton
            (click)="save()"
            [showWarn]="true">
            <mat-icon>save</mat-icon>
        </button>
        <button
            mat-icon-button
            *ngIf="isActivable"
            [matTooltip]="_translocoContent()['activate']"
            (click)="activate()">
            <mat-icon svgIcon="mat_solid:share"></mat-icon>
        </button>
    </div>
    <div rightContent>
        <org-sys-area-editor [inDevelopmentStatus]="inDevelopmentStatus"></org-sys-area-editor>
    </div>
</content-layout-wrapper>

<!-- <ng-template class="hidden" [config]="printComponent" componentFactory></ng-template> -->
